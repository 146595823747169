body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.MuiAppBar-root {
  z-index: 1400; /* Ensure AppBar is above Sidebar */
}

.MuiDrawer-paper {
  transition: width 0.3s ease; /* Smooth transition for sidebar width */
  position: fixed; /* Ensure it is fixed in the layout */
}
